import styled, { css } from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const StyledSectionWrapper = styled(BackgroundImage)`
  background-color: ${({ theme }) => theme.colors.black};
  position: relative;
  z-index: 0;
  padding: 103px 0;
  ${({ theme }) => theme.mq.lg} {
    padding: 203px 0;
  }
`;

export const StyledTitle = styled('h2')`
  font-size: 2.4rem;
  line-height: 3.6rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 6.4rem;
  ${({ theme }) => theme.mq.lg} {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }
`;

export const StyledGroupTitle = styled('h3')`
  font-size: 2.1rem;
  line-height: 2.5rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 2.4rem;
`;

export const StyledFormWrapper = styled('div')`
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    z-index: -1;
    background: rgba(19, 19, 19, 0.9);
  }
`;

export const StyledFormInnerWrapper = styled('div')`
  position: relative;
`;

export const StyledBlurWrapper = styled('div')`
  overflow: hidden;
  padding: 3rem;
  max-width: 100%;
  background: ${({ theme }) => theme.colors.primary};
  ${({ theme }) => theme.mq.smm} {
    margin: 0 2.5rem;
    border-radius: 4px;
  }
  ${({ theme }) => theme.mq.md} {
    padding: 3.6rem 4.2rem;
  }
  ${({ theme }) => theme.mq.lg} {
    max-width: 830px;
    margin: 0 auto;
  }
`;

export const StyledForm = styled('form')`
  width: 100%;
  margin: 0;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.primary};
  transition: filter 0.5s;
  &::after {
    transition: 0.5s;
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0);
  }
  ${({ theme }) => theme.mq.smm} {
    border-radius: 5px;
  }
  ${({ blur }) =>
    blur &&
    css`
      filter: blur(4px);
      position: relative;
      &::after {
        opacity: 1;
        visibility: visible;
        overflow: visible;
        background-color: rgba(255, 255, 255, 0.8);
      }
    `}
`;

export const StyledInputRow = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  ${({ theme }) => theme.mq.md} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const StyledSubmitBtn = styled('button')`
  width: 100%;
  padding: 15px;
  text-align: center;
  border-radius: 4px;
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 500;
  background-color: ${({ theme }) => theme.colors.textBrown};
  color: ${({ theme }) => theme.colors.white};
  transition: all 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.textBrown};
  }
`;
